function fixedDigits(num, digits = 2) {
    if(typeof num != 'string')
        num = num.toString()
    if(num.length > digits)
        num = num.substr(num.length - digits)
    while(num.length < digits)
        num = '0' + num
    return num
}

function dateFormat(date, format = 'dd.MM.yyyy hh:mm:ss') {
    if(typeof date == "string" || typeof date == "number")
        return dateFormat(new Date(date), format)
    return format
        .replace('dd', fixedDigits(date.getDay() + 1))
        .replace('MM', fixedDigits(date.getMonth() + 1))
        .replace('yyyy', fixedDigits(date.getFullYear(), 4))
        .replace('yy', fixedDigits(date.getFullYear()))
        .replace('hh', fixedDigits(date.getHours()))
        .replace('mm', fixedDigits(date.getMinutes()))
        .replace('ss', fixedDigits(date.getSeconds()))
}

export default dateFormat