<template>
    <div class="px-page mb-5">
        <datatable path="/testimonials" :headers="[
            {text: 'Datum', value: 'created_at'},
            {text: 'Quelle', value: 'source'},
            {text: 'Author', value: 'author'},
            {text: 'Titel', value: 'title'},
            {text: 'Bewertung', value: 'rating'},
            ...(showAll ? [{text: 'Aktionen', value: 'actions'}] : [{text: 'Bestätigt', value: 'verified'}])
        ]" ref="table" class="mt-5">
            <template v-slot:actions>
                <v-btn color="primary" small @click="startImport()">Import starten</v-btn>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
                {{ dateFormat(item.created_at, 'dd.MM.yyyy hh:mm') }}
            </template>
            <template v-slot:[`item.source`]="{ item }">
                <v-chip color="green darken-1" class="white--text" small v-if="item.source == 'TRUSTPILOT'">TrustPilot</v-chip>
                <v-chip color="primary" small v-else-if="item.source == 'SUPPORT_TICKET'">Support Ticket</v-chip>
                <v-chip color="gray" class="white--text" small v-else>{{ item.source }}</v-chip>
            </template>
            <template v-slot:[`item.rating`]="{ item }">
                <v-rating half-increments :value="item.rating / 2" length="5" readonly dense />
            </template>
            <template v-slot:[`item.title`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" style="cursor: pointer;">{{ item.title }}</span>
                    </template>
                    <div style="max-width: 400px;">{{ item.text }}</div>
                </v-tooltip>
            </template>
            <template v-slot:[`item.verified`]="{ item }">
                <v-tooltip bottom v-if="item.verified">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" @click="unverify(item.id)">mdi-check</v-icon>
                    </template>
                    <span>Klicke zum Ausblenden</span>
                </v-tooltip>
                <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" @click="verify(item.id)">mdi-close</v-icon>
                    </template>
                    <span>Klicke zum Bestätigen</span>
                </v-tooltip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn small color="primary" @click="verify(item.id)" v-if="!item.verified">Bestätigen</v-btn>
            </template>
        </datatable>
    </div>
</template>

<script>
import xeonserv from '../../apis/xeonserv'
import dateFormat from '../../util/dateFormat'

export default {
    data() {
        return {
            showAll: false
        }
    },
    methods: {
        verify(id) {
            xeonserv().post('/testimonials/' + id + '/verify').then(() => this.$refs.table.$emit('reloaddata'))
        },
        unverify(id) {
            xeonserv().post('/testimonials/' + id + '/unverify').then(() => this.$refs.table.$emit('reloaddata'))
        },
        startImport() {
            xeonserv().post('/testimonials/import')
        },
        dateFormat
    }
}
</script>