var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-page mb-5"},[_c('datatable',{ref:"table",staticClass:"mt-5",attrs:{"path":"/testimonials","headers":[
        {text: 'Datum', value: 'created_at'},
        {text: 'Quelle', value: 'source'},
        {text: 'Author', value: 'author'},
        {text: 'Titel', value: 'title'},
        {text: 'Bewertung', value: 'rating'} ].concat( (_vm.showAll ? [{text: 'Aktionen', value: 'actions'}] : [{text: 'Bestätigt', value: 'verified'}])
    )},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.startImport()}}},[_vm._v("Import starten")])]},proxy:true},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.created_at, 'dd.MM.yyyy hh:mm'))+" ")]}},{key:"item.source",fn:function(ref){
    var item = ref.item;
return [(item.source == 'TRUSTPILOT')?_c('v-chip',{staticClass:"white--text",attrs:{"color":"green darken-1","small":""}},[_vm._v("TrustPilot")]):(item.source == 'SUPPORT_TICKET')?_c('v-chip',{attrs:{"color":"primary","small":""}},[_vm._v("Support Ticket")]):_c('v-chip',{staticClass:"white--text",attrs:{"color":"gray","small":""}},[_vm._v(_vm._s(item.source))])]}},{key:"item.rating",fn:function(ref){
    var item = ref.item;
return [_c('v-rating',{attrs:{"half-increments":"","value":item.rating / 2,"length":"5","readonly":"","dense":""}})]}},{key:"item.title",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"}},'span',attrs,false),on),[_vm._v(_vm._s(item.title))])]}}],null,true)},[_c('div',{staticStyle:{"max-width":"400px"}},[_vm._v(_vm._s(item.text))])])]}},{key:"item.verified",fn:function(ref){
    var item = ref.item;
return [(item.verified)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.unverify(item.id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-check")])]}}],null,true)},[_c('span',[_vm._v("Klicke zum Ausblenden")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.verify(item.id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-close")])]}}],null,true)},[_c('span',[_vm._v("Klicke zum Bestätigen")])])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(!item.verified)?_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.verify(item.id)}}},[_vm._v("Bestätigen")]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }